<template>
  <div>
    <!--    <van-nav-bar title="单银幕产出" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--    </van-nav-bar>-->
    <div class="table">
      <div class="table-header">
        <div>月份</div>
        <div>单银幕产出</div>
        <div>本地平均单银幕产出</div>
      </div>
      <div class="table-content">
        <div
          class="table-content-item"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div>
            {{ item.mouth }}
          </div>
          <div>{{ item.Produce }}元</div>
          <div>{{ item.localProduce }}元</div>
        </div>
      </div>
      <div class="table-content">
        <div class="table-content-item">
          <div>总平均</div>
          <div>{{ AvgScreenValue }}元</div>
          <div>{{ CityAvgScreenValue }}元</div>
        </div>
      </div>
    </div>
    <div class="desc">
      说明：页面展示1月-本月平均每个月单银幕产出值和本地城市平均银幕产出值，总平均展示本影城单银幕产出和本地城市平均银幕产出
      <br />
      更新时间：{{ updateTime }}
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'yearBoxOffice',
  data() {
    return {
      dataList: [],
      AvgScreenValue: 0,
      CityAvgScreenValue: 0,
      updateTime: '2018-01-01',
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101603","cmdArgs":["${this.cinemaId}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        const { Success, Message, data } = r
        if (!Success) {
          Toast(Message)
        } else {
          this.updateTime = data.UpdateDate
          this.AvgScreenValue = data.AvgScreenValue
          this.CityAvgScreenValue = data.CityAvgScreenValue
          this.dataList = data.monthDatas.map((item) => {
            return {
              mouth: `${item.m}月`,
              Produce: `${item.AvgScreenValue}`,
              localProduce: `${item.CityAvgScreenValue}`,
            }
          })
        }
        // console.log({ 单荧幕: r })
      })
    },
    toTopPage() {
      window.history.back()
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #3d8bff;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */
  /deep/ .van-nav-bar__title {
    color: white;
  }
  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}
.table {
  font-size: 0.4rem;
  &-header {
    background: #f2f2f2;
    display: flex;
    height: 1.2rem;
    align-items: center;
    > div {
      flex: 2;
      text-align: center;
      &:first-child {
        flex: 1;
      }
    }
  }
  &-content {
    &-item {
      &:nth-child(2n) {
        background-color: #f6f7f8;
      }
      display: flex;
      height: 1rem;
      align-items: center;
      > div {
        flex: 2;
        text-align: center;
        &:first-child {
          flex: 1;
        }
      }
    }
  }
}
.desc {
  padding: 0.3rem;
  font-size: 13px;
  color: rgba(93, 93, 96, 0.44);
}
.red {
  color: #ff5c5c;
}
.green {
  color: #5dea27;
}
</style>
